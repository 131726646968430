import * as React from 'react'
import IngredientTile from '../components/shared/IngredientTile'
import { compact, uniqBy } from 'lodash'

import Layout from '../layouts'

import './index.scss'

const categorizeIngredients = (ingredients) => {
  const ingredientMap = {
    finalized: [],
    unFinalized: []
  }

  ingredients.forEach((ingredient) => {
    if (ingredient.finalized) {
      ingredientMap.finalized.push(ingredient)
    } else {
      ingredientMap.unFinalized.push(ingredient)
    }
  })

  return ingredientMap
}

const IndexPage = ({ data }) => {
  const allIngredients = data.allContentfulRecipe.nodes.map((node) => node.ingredients).flat().map(ingredient => ingredient.ingredient)
  const compactedArray = compact(allIngredients)
  const uniqueIngredients = uniqBy(compactedArray, (ingredient) => ingredient.contentful_id)
  const categorizedIngredients = categorizeIngredients(uniqueIngredients)

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-3">
            <h4>Done: {categorizedIngredients.finalized.length}</h4>
            <h4>To Do: {categorizedIngredients.unFinalized.length}</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h2>Finalized</h2>
          </div>
          {categorizedIngredients.finalized.map((product) => (
            <IngredientTile ingredient={product} key={`ingredient-tile-${product.sku}`} />
          ))}
        </div>
        <div className="row">
          <div className="col-12">
            <h2>Un-Finalized</h2>
          </div>
          {categorizedIngredients.unFinalized.map((product) => (
            <IngredientTile ingredient={product} key={`ingredient-tile-${product.sku}`} />
          ))}
        </div>
      </div>
    </Layout >
  )
}

export const query = graphql`
query {
  allContentfulRecipe(filter: {nonProduction: {ne: true}}) {
    nodes {
      ingredients {
        ingredient {
          id
          name
          sku
          priceCents
          section
          brand
          quantityDescription
          finalized
          contentful_id
          picture {
            file {
              url
            }
            fluid(quality: 100, maxWidth:150) {
              src
              base64
            }
          }
        }
      }
    }
  }
}

`

export default IndexPage
